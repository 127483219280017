import React from "react";
import icon1 from "../images/sketch-1.avif"
import icon2 from "../images/sketch-2.avif"
import icon3 from "../images/sketch-3.avif"
import { useInView } from "react-intersection-observer";


const ReasonsComponent = () => {

    const [sectionRef, inView] = useInView({
        triggerOnce: true,
    });

    return <div ref={sectionRef} className={`animate__animated ${inView ? 'animate__fadeInLeft' : ''} my-6 md:mx-20 mx-2`}>
        <div className="md:p-8 md:mx-20 sm:px-8">
            <div className="grid md:grid-cols-3 sm:grid-cols-1 my-4 font-custom text-shizen text-2xl font-bold content-center">
                <div className="grid grid-rows-2 gap-2">
                    <img src={icon1} alt="test" className="justify-self-center" />
                    <p className="uppercase justify-self-center">Soluții personalizate</p>
                </div>
                <div className="grid grid-rows-2 gap-2">
                    <img src={icon2} alt="test" className="justify-self-center" />
                    <p className="uppercase justify-self-center">Calitate superioara</p>
                </div>
                <div className="grid grid-rows-2 gap-2">
                    <img src={icon3} alt="test" className="justify-self-center" />
                    <p className="uppercase justify-self-center">Experiență dovedită</p>
                </div>
            </div>
        </div>
    </div>
}

export default ReasonsComponent;