import React from "react";

const TextComponent = ({ content }) => {

    return (
        <div className="py-16">
            <div className="px-4 divide-y-2 font-custom divide-neutral-500">
                <h2 className="text-3xl text-stone-700 font-bold uppercase mb-2">{content.title}</h2>
                <p className="text-shizen md:text-2xl py-4">{content.text}</p>
            </div>
            <div className="flex justify-start px-4">
                <a href={content.redirectUrl} className="bg-shizen hover:hover:bg-neutral-800 text-white font-custom px-4 py-2 rounded-md uppercase no-underline">Vezi mai mult</a>
            </div>
        </div>
    )
}

export default TextComponent;