import React from "react";
import consultingImage from "../images/consultanta.avif";
import { useInView } from "react-intersection-observer";

const ConsultingComponent = () => {
    const [sectionRef, inView] = useInView();

    return <div ref={sectionRef} className="grid md:grid-cols-2 sm:grid-cols-1 gap-8 section py-16 md:mx-20">
        <div className={`animate__animated ${inView ? 'animate__fadeInLeft' : ''} font-custom text-shizen text-center self-center px-4 md:px-0`}>
            <p className="text-4xl font-bold uppercase">Consultanță</p>
            <p className="text-2xl">Dacă îți dorești să împrospătezi aerul locuinței tale și nu ai nevoie de un proiect complet de design interior, acest serviciu vine în ajutorul tău. Îți oferim ghidarea necesară pentru a putea integra acele detalii și elemente ce vor face din locuința ta un spațiu revitalizat și unitar. În urma consultațiilor, veți primi recomandări, panouri cu propuneri de achiziții și eventuale schițe pentru a vă ușura implementarea proiectului.</p> </div>
        <div className={`animate__animated ${inView ? 'animate__fadeInRight' : ''}`}>
            <img src={consultingImage} alt="consultanta" />
        </div>
    </div>
}

export default ConsultingComponent;