import React from "react";
import ServiceComponent from "./ServiceComponent";
import first_meeting from "../images/meeting.avif";
import technical from "../images/detaliere_tehnica.avif";
import functional from "../images/functionalitate.avif";
import assistance from "../images/asistenta.avif";
import leftArrow from "../images/arrow-left.avif";
import rightArrow from "../images/arrow-right.avif";
import { useInView } from "react-intersection-observer";

const FullDesignServiceComponent = () => {
    const [reference, inViewMain] = useInView();

    const data = [
        {
            image: first_meeting,
            altText: "Prima întâlnire",
            orientation: "right",
            content: {
                title: "1. Prima întâlnire",
                text: "Deoarece fiecare proiect este realizat exclusiv pentru nevoile și cerințele clientului, este necesar să cunoaștem cât mai bine personalitatea și stilul de viață al acestuia. În aceste discuții se vor aborda subiecte precum preferințele estetice ale clientului, stadiul actual al spațiului, bugetul alocat amenajării și timpii atribuiți proiectării și implementării proiectului.",
                image: rightArrow
            }
        },
        {
            image: technical,
            altText: "Funcționalitate și design",
            orientation: "left",
            content: {
                title: "2. Funcționalitate și design",
                text: "În prima etapă a proiectului vorbim despre funcționalitatea spațiului și explorăm mai multe idei și propuneri estetice, urmând apoi să le concretizăm sub forma unor vizualizări 3D prin care să reflectăm conceptul de design dorit.",
                image: leftArrow
            }
        },
        {
            image: functional,
            altText: "Detaliere tehnică",
            orientation: "right",
            content: {
                title: "3. Detaliere tehnică",
                text: "Având un concept de design final, etapa de detaliere tehnică este necesară pentru ca proiectul să poată deveni realitate. În această etapă vom stabili de asemenea și listele de achiziții și vom face ofertarea acestora.",
                image: rightArrow
            }
        },
        {
            image: assistance,
            altText: "Asistență în implementare",
            orientation: "left",
            content: {
                title: "4. Asistență în implementare",
                text: "Pe toată durata implementării proiectului vom oferi asistență și suport tehnic în relație cu echipa de execuție. Vom face vizite de șantier la momentele cheie ale proiectului și ne vom asigura că toate detaliile proiectului sunt implementate conform proiectului.",
                image: null
            }
        },
    ]

    return <div>
        <div ref={reference} className={`animate__animated ${inViewMain ? 'animate__fadeIn' : ''} font-custom text-shizen text-center`}>
            <div className="text-4xl font-bold uppercase">Servicii complete de design interior</div>
        </div>
        <div className="space-y-8 py-4 md:mx-40">
            {data.map((element) => {
                return <ServiceComponent data={element} key={element.altText} />
            })}
        </div>
    </div>
}

export default FullDesignServiceComponent;