import React from "react";
import { ROUTES } from "../../constants/routes";

const FeedbackItem = ({ data }) => {
    return <div className="flex flex-wrap justify-center mt-2 text-shizen font-custom text-xl">
        {
            data.feedback.length > 750 && <div className="flex items-start text-center min-h-96 max-h-96 w-4/5 overflow-auto py-4">
                {data.feedback}
            </div>
        }
        {
            data.feedback.length <= 750 && <div className="flex items-center text-center min-h-96 max-h-96 w-4/5 overflow-auto py-4">
                {data.feedback}
            </div>
        }
        <div className="w-full text-center font-bold text-stone-700">{data.name}</div>
        <div className="w-full text-center font-bold text-stone-700 pb-4">{data.project}</div>
        <div className="flex justify-center items-center gap-2">
            <a href={ROUTES.PORTOFOLIU + '/' + data.projectNumber} className="bg-shizen hover:bg-neutral-800 text-white px-4 py-2 rounded-md uppercase no-underline font-custom text-center">Vezi proiectul</a>
            {null !== data.profileUrl ?
                <a href={data.profileUrl} target="_blank" rel="noreferrer" className="bg-shizen hover:bg-neutral-800 text-white px-4 py-2 rounded-md uppercase no-underline font-custom text-center">Vezi clientul</a>
                : <></>
            }
        </div>
    </div>
}

export default FeedbackItem;