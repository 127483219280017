// @ts-nocheck
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ROUTES } from './constants/routes';
import Homepage from './pages/Home';
import AboutUs from './pages/AboutUs';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
import Navbar from './components/navbar/Navbar';
import './App.css';
import Footer from './components/common/Footer';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from './components/common/ScrollToTop';
import PortfolioProject from './components/portfolio/PortfolioProject';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route
          path={ROUTES.ACASA}
          element={<Homepage />}
        />
        <Route
          path={ROUTES.DESPRE_NOI}
          element={<AboutUs />}
        />
        <Route
          exact path={ROUTES.PORTOFOLIU}
          element={<Portfolio />}
        />
        <Route
          path={ROUTES.SERVICII}
          element={<Services />}
        />
        <Route
          path={ROUTES.PORTOFOLIU + "/:projectId"}
          element={<PortfolioProject />}
        />
        <Route
          path='*'
          element={<Homepage />}
        />

      </Routes>
      <Footer />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
