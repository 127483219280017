import React from "react";
import { useInView } from "react-intersection-observer";

const TopperImage = ({ data }) => {
    const [sectionRef, inView] = useInView({
        triggerOnce: true,
    });

    return <div ref={sectionRef} className={`animate__animated ${inView ? 'animate__fadeInDown' : ''}`}>
        <div className="relative">
            <img src={data.image} alt="Topper" />
            <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white font-custom">
                <div className="text-center flex items-center justify-center h-full uppercase slide-text">
                    <h2 className="flex items-center justify-center h-full uppercase slide-text text-3xl font-bold sm:text-center px-4 slide-text">{data.text}</h2>
                </div>
            </div>
        </div>
    </div>
}

export default TopperImage;