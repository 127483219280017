import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const root = ReactDOM.createRoot(document.getElementById('root'));

const firebaseConfig = {
  apiKey: "AIzaSyA-aIDWqiI9HML68XyM20SXnBIUNDMEnr8",
  authDomain: "shizen-design.firebaseapp.com",
  projectId: "shizen-design",
  storageBucket: "shizen-design.appspot.com",
  messagingSenderId: "437589901890",
  appId: "1:437589901890:web:56b6e49f4e2b8cbd60a3db",
  measurementId: "G-B0MFCEJY0J"
};

const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);

root.render(
  <App />
);

reportWebVitals();
