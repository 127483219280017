import React, { useEffect, useState } from "react";
import ServiceTextComponent from "./ServiceTextComponent";
import { useInView } from "react-intersection-observer";

const ServiceComponent = ({ data }) => {
    const [sectionRef, inView] = useInView();
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 750) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    }, [])

    return <div ref={sectionRef} className="flex font-custom text-shizen text-center">
        {
            isMobileView === true && <div className="flex justify-center md:space-x-16 sm:space-x-4 flex-col-reverse md:flex-row md:mx-20 mx-2">
                <div className={`animate__animated ${inView ? 'animate__fadeInUp' : ''} w-full md:w-1/2`}>
                    <img src={data.image} alt={data.altText} className="w-full h-auto" />
                    {data.content.image !== null && <img src={data.content.image} alt="arrow" />}
                </div>
                <div className={`animate__animated ${inView ? 'animate__fadeInUp' : ''} w-full md:w-1/2`}>
                    <div className="grid justify-items-start content-center h-full">
                        <div className="text-3xl font-bold uppercase">{data.content.title}</div>
                        <div className="text-2xl text-start py-4">{data.content.text}</div>
                    </div>
                </div>
            </div>
        }
        {
            isMobileView === false && data.orientation === "left" &&
            <div className="flex justify-center md:space-x-16 sm:space-x-4 flex-col-reverse md:flex-row md:mx-20 mx-2">
                <div className={`animate__animated ${inView ? 'animate__fadeInUp' : ''} w-full md:w-1/2`}>
                    <img src={data.image} alt={data.altText} className="w-full h-auto" />
                </div>
                <div className={`animate__animated ${inView ? 'animate__fadeInUp' : ''} w-full md:w-1/2`}>
                    <ServiceTextComponent data={data.content} />
                </div>
            </div>
        }
        {
            isMobileView === false && data.orientation === "right" &&
            <div className="flex justify-center md:space-x-4 flex-col-reverse md:flex-row md:mx-20 mx-2">
                <div className={`animate__animated ${inView ? 'animate__fadeInUp' : ''} w-full md:w-1/2`}>
                    <ServiceTextComponent data={data.content} />
                </div>
                <div className={`animate__animated ${inView ? 'animate__fadeInUp' : ''} w-full md:w-1/2`}>
                    <img src={data.image} alt={data.altText} className="w-full h-auto" />
                </div>
            </div>
        }
    </div>
}

export default ServiceComponent;