const PROJECTS_PHOTOS = {
    1: {
        numberOfPictures: 16,
        title: 'Sage Meadown Apartment'
    },
    2: {
        numberOfPictures: 13,
        title: 'Dark Industrial Apartment'
    },
    3: {
        numberOfPictures: 12,
        title: 'Warm Japandi Apartment'
    },
    4: {
        numberOfPictures: 14,
        title: 'Red Dot Apartment'
    },
    5: {
        numberOfPictures: 11,
        title: 'Liberty Apartment'
    },
    6: {
        numberOfPictures: 14,
        title: 'Dej House'
    },
    7: {
        numberOfPictures: 13,
        title: 'City Center House'
    },
    8: {
        numberOfPictures: 12,
        title: 'Olive Apartment'
    },
    9: {
        numberOfPictures: 13,
        title: 'The Nature Apartment'
    },
    10: {
        numberOfPictures: 12,
        title: 'Terazzo Airbnb Apartment'
    },
    11: {
        numberOfPictures: 8,
        title: 'Circle Rental Apartment'
    },
    12: {
        numberOfPictures: 11,
        title: 'River View Apartment'
    },
    13: {
        numberOfPictures: 11,
        title: 'MA Apartment'
    },
    14: {
        numberOfPictures: 8,
        title: 'Kids Room'
    },
}

export default PROJECTS_PHOTOS;