import React from "react";
import ContactComponent from "./ContactComponent";
import sal from "../images/anpc-sal.avif";
import sol from "../images/anpc-sol.avif";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {

    return <footer className="footer footer-center w-full h-full font-custom">
        <ContactComponent />
        <div className="text-center bg-shizen">
            <div className="pt-4">
                <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noreferrer">
                    <LazyLoadImage src={sal} alt="sal-icon" effect="blur" height={100} width={300} />
                </a>
                <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noreferrer">
                    <LazyLoadImage src={sol} alt="sol-icon" effect="blur" height={100} width={300} />
                </a>
            </div>
            <div className="font-bold pb-4">
                Copyright © 2024 - Shizen Design
            </div>
        </div>
    </footer>
}

export default Footer;