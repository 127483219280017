import React from "react";
import PortfolioListing from "../components/portfolio/PortfolioListing";
import { Helmet } from "react-helmet";

const Portfolio = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shizen Interior Design | Portofoliu</title>
        <meta name="description"
          content="Portofoliul cu lucrări de design interior ale Shizen Interior Design"
        />
        <meta name="keywords" content="Design Interior, Portofoliu, Cluj-Napoca" />
        <link rel="canonical" href="https://shizenstudiodesgin.com/"></link>
      </Helmet>
      <h1 className="hidden">Portofoliu</h1>
      <PortfolioListing />
    </>
  );
}

export default Portfolio; 