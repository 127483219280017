import React from "react";
import FeedbackItem from "./FeedbackItem";
import { Carousel } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

const FeedbackSection = () => {
    const [sectionRef, inView] = useInView({
        triggerOnce: true,
    });

    const data = [
        {
            name: "Oana F.",
            project: "The Nature Apartment",
            feedback: "”Ne-a plăcut foarte mult colaborarea cu Ioana. Ne-au fost recomandate idei ușor de pus în practică. A intervenit rapid ca să găsească alte soluții atunci când a fost necesar. De asemenea, piesele de mobilier pe care le-am luat la recomandarea ei sunt calitative și se integrează perfect în design.”",
            projectNumber: '9',
            profileUrl: null,
        },
        {
            name: "Ana B.",
            project: "Modern Farmhouse",
            feedback: "”Am avut o colaborare reusita cu Ioana, e o persoana deschisa cu care se poate comunica foarte ușor. A intuit de la început stilul amenajării pe care ni- l doream și ne - a oferit soluții foarte relevante, adaptate cerintelor noastre.E o persoana antrenata sa vadă cea mai buna poziționare a mobilierului, sa folosească eficient fiecare spațiu și sa pună totul laolaltă in așa fel încât rezultatul sa fie unul atât estetic cat și practic.Am apreciat enorm faptul ca a respectat toate termenele de timp pe care le - am stabilit împreună și ca toate elementele proiectului nostru de amenajare(planșe tehnice, randari, oferte etc) au fost de calitate, făcute cu responsabilitate.Recomand cu încredere, eu personal voi apela la serviciile Ioanei de câte ori voi avea nevoie de un designer interior.”",
            projectNumber: '6',
            profileUrl: null,
        },
        {
            name: "Szidonia H.",
            project: "Olive Apartment",
            feedback: "”Ne-a plăcut enorm munca ei și cu siguranță as recomanda o colaborare cu Ioana. Ascultă cu atenție, înțelege cerințele și le transformă in realitate. Ne-a livrat un proiect care a combinat toate dorințele și necesitățile noastre, dar compensând cu expertiza ei. În final am primit un design minunat, cu planuri bine conturate și o imagine clară a lucrărilor care trebuie efectuate.”",
            projectNumber: '8',
            profileUrl: null,
        },
        {
            name: "Teodora B.",
            project: "Terazzo Apartment",
            feedback: "”Colaborarea mea cu Shizen Design a fost una deosebită. Am avut nevoie de amenajarea unei garsoniere (29 mp) pentru a fi închiriată în regim hotelier. Ioana ne-a ajutat foarte mult cu conceptul fără să-i dăm prea multe detalii, a intuit foarte bine și a știut să pună în valoare întreg spațiul, ne-a dat idei foarte bune pe care le-am pus în practică și astfel amenajarea unui spațiu relativ mic a ieșit excelent, mult mai bine decât ne puteam imagina. Randările, planșele tehnice, devizele pentru finisaje, obiecte de mobilier, sanitare, corpuri de iluminat etc. au fost executate în detaliu, cu foarte mare atenție, iar la orice întrebare/neclaritate am primit răspuns foarte prompt. De asemenea, ne-a pus în legătură cu firmele de la care am achiziționat materialele, mobila etc. foarte serioase și cu produse de foarte bună calitate. O recomand cu foarte mare încredere pe Ioana și îi mulțumim încă o dată pentru toata implicarea și munca depusă!”",
            projectNumber: '10',
            profileUrl: null,
        },
        {
            name: "Ioana O.",
            project: "Liberty Apartment",
            feedback: "”Ioana este o persoană foarte creativă și ingenioasă, atentă la detalii și care vine cu idei pentru orice problema din amenajare. Mereu a dat dovadă de profesionalism, fiind flexibilă și deschisă atunci când am dorit modificări. O recomand oricui care își dorește un proiect reușit și gândit bine!”",
            projectNumber: '5',
            profileUrl: null,
        },
        {
            name: "Alexandra D.",
            project: "Warm Japandi Apartment",
            feedback: "”Sunt foarte recunoscătoare pentru colaborarea cu Ioana. De la început a avut răbdare să înțeleagă cât mai precis conceptul, stilul pe care ni-l dorim și să ne explice etapele procesului foarte deschis și transparent, astfel încat să înțelegem și noi ce anume trebuie făcut ca locului pe care îl vom numi 'casă' să iasă cât mai frumos și în buget. Mi-a plăcut profesionalismul de la prima discuție, fiecare termen a fost respectat, ba chiar știind că suntem presați cu timpul și bugetul, Ioana a făcut un efort și ne-a livrat înainte de estimare designul și fișele tehnice pentru a putea începe finisarea cât mai repede cu echipele de renovare și asta ne-a ajutat foarte mult. Am simțit că am colaborat cu un prieten, sfaturile orientate spre bine, fiecare propunere a fost centrată pe confortul nostru și pentru a ne ajuta să optimizam costurile. Trebuie să menționez că am pus și multe întrebări, neștiind deloc detalii tehnice despre design, renovări, materiale sau etape și mereu a răspuns cu răbdare pentru a mă face să înțeleg exact ce se întâmplă. Am simțit foarte puțin stres în tot procesul știind că ne asistă Ioana pentru a alege corect fiecare element din amenajare. Ar fi fost mai mult decât imposibil să amenajăm fără ea având în paralel și stresul de la job. Pot să scriu la nesfârșit de bine aici, așa bucuroasă sunt că am dat peste Ioana. Concluzionând, proiectul a ieșit exact cum ne-am dorit, un loc unde te simți acasă iar dacă oricine are vreo întrebare despre proces sau design sau vrea să vadă cum a ieșit, sunt bucuroasă să răspund fiecărei întrebări.”",
            projectNumber: '3',
            profileUrl: "https://www.linkedin.com/in/alexandra-drobut-15629111a",
        }
    ];

    return <div ref={sectionRef} className={`animate__animated ${inView ? 'animate__fadeInUp' : ''} flex flex-col items-center justify-center mt-8`}>
        <p className="text-3xl uppercase text-center font-bold font-custom text-stone-700">
            <strong>Testimoniale</strong>
        </p>
        <Carousel
            slide={true}
            wrap={true}
            interval={3500}
            className="carousel-feedback md:w-3/4 sm:w-full max-h-128 min-h-128 rounded-md shadow-md"
        >
            {data.map((item) => {
                return <Carousel.Item key={item.name}>
                    <FeedbackItem data={item} />
                </Carousel.Item>
            })}
        </Carousel>
    </div>
}

export default FeedbackSection;