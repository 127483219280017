import React, { useState } from "react";
import logo from "../images/logo.avif";
import { ROUTES } from "../../constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <nav className="lg:px-16 px-6 bg-white shadow-md flex flex-wrap items-center lg:py-0 py-2 sticky top-0 z-50">
            <div className="flex-1 flex justify-between items-center">
                <div className="flex">
                    <a href="/" className="flex text-lg font-semibold">
                        <img
                            src={logo}
                            width="100"
                            height="100"
                            className="p-2"
                            alt="Shizenn Design Logo"
                        />
                    </a>
                    <div className="flex items-center space-x-4">
                        <a href="https://www.facebook.com/shizen.interiordesign" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebook} color="#968476" />
                        </a>
                        <a href="https://www.instagram.com/shizen.design/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} color="#968476" />
                        </a>
                    </div>
                </div>
            </div>
            <label htmlFor="menu-toggle" className="cursor-pointer lg:hidden block" onClick={toggleMenu}>
                <svg
                    className="fill-current text-gray-900"
                    xmlns="https://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                >
                    <title>menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
            </label>
            <input className="hidden" type="checkbox" id="menu-toggle" checked={menuOpen} readOnly />
            <div className="hidden lg:flex lg:items-center lg:w-auto w-full font-bold font-custom uppercase" id="menu">
                <nav>
                    <ul className="text-xl text-center items-center gap-x-5 pt-4 md:gap-x-4 lg:text-lg lg:flex  lg:pt-0">
                        <li className="py-2 lg:py-0 ">
                            <a
                                className="text-shizen hover:pb-4 hover:border-b-4 hover:border-shizen no-underline"
                                href={ROUTES.ACASA}
                                onClick={toggleMenu}
                            >
                                Acasă
                            </a>
                        </li>
                        <li className="py-2 lg:py-0 ">
                            <a
                                className="text-shizen hover:pb-4 hover:border-b-4 hover:border-shizen no-underline"
                                href={ROUTES.DESPRE_NOI}
                                onClick={toggleMenu}
                            >
                                Despre Noi
                            </a>
                        </li>
                        <li className="py-2 lg:py-0 ">
                            <a
                                className="text-shizen hover:pb-4 hover:border-b-4 hover:border-shizen no-underline"
                                href={ROUTES.SERVICII}
                                onClick={toggleMenu}
                            >
                                Servicii Design
                            </a>
                        </li>
                        <li className="py-2 lg:py-0 ">
                            <a
                                className="text-shizen hover:pb-4 hover:border-b-4 hover:border-shizen no-underline"
                                href={ROUTES.PORTOFOLIU}
                                onClick={toggleMenu}
                            >
                                Portofoliu
                            </a>
                        </li>
                        <li className="py-2 lg:py-0 ">
                            <a
                                className="text-shizen hover:pb-4 hover:border-b-4 hover:border-shizen no-underline"
                                href="#contact-section"
                                onClick={toggleMenu}
                            >
                                Contact
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </nav>
    );
}

export default Navbar;