import React from "react";

const ServiceTextComponent = ({ data }) => {

    return <div className="grid justify-items-start content-center h-full">
        <div className="text-3xl font-bold uppercase">{data.title}</div>
        <div className="text-2xl text-start py-4">{data.text}</div>
        <div className="flex justify-center w-full">
            {data.image !== null && <img src={data.image} alt="alt-text" />}
        </div>
    </div>
}

export default ServiceTextComponent;