import React from "react";
import ioana from "../images/ioana.avif";
import { useInView } from "react-intersection-observer";

const AboutUsSection = () => {
    const [sectionRefRight, inViewRight] = useInView();
    const [sectionRefLeft, inViewLeft] = useInView();

    return <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center my-6 md:mx-20">
        <div ref={sectionRefLeft} className={`animate__animated ${inViewLeft ? 'animate__fadeInLeft' : ''} flex items-center align-center place-content-center`}>
            <img src={ioana} alt="ioana" className="w-full h-full" />
        </div>
        <div ref={sectionRefRight} className={`animate__animated ${inViewRight ? 'animate__fadeInRight' : ''} text-shizen font-custom text-2xl text-center mx-4 sm:mx-0 flex justify-center items-center`}>
            <p><b>Shizen Design</b> este un studio de design interior fondat în anul 2022 care își propune să creeze spații unice, ce reflectă personalitatea fiecărui client în parte. Credem că niciun stil arhitectural nu ne poate reprezenta în totalitate, așadar fiecare amenajare este unică prin combinația de stiluri și elemente personale, rezultând spații cu personalitate. Acest lucru este surprins și în numele nostru. Shizen este un cuvânt japonez, se pronunță "shee-zen" și se traduce ca fiind natură, natura umană. Natura umană este laitmotivul nostru ca și studio, iar prin fiecare proiect realizat, dorim să surprindem natura fiecăruia în spațiile proiectate. Fiind designer de interior și totodată inginer în construcții, Ioana este persoana potrivită care poate combina creativitatea cu partea tehnică a unui proiect, reușind astfel să transforme ideile în realitate.</p>
        </div>
    </div>

}

export default AboutUsSection;