import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PROJECTS_PHOTOS from "../../constants/projectsImages";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from "react-helmet";

const PortfolioProject = () => {
    const projectId = useParams().projectId ?? null;
    const [images, setImages] = useState([]);
    const [title, setTitle] = useState(null)

    useEffect(() => {
        if (null !== projectId) {
            const numberOfImages = PROJECTS_PHOTOS[projectId]['numberOfPictures'];
            const projectTitle = PROJECTS_PHOTOS[projectId]['title'];

            setTitle(projectTitle);

            let promises = [];

            for (let i = 1; i <= numberOfImages; i++) {
                promises.push(import(`./projects/${projectId}/${i}.jpeg`).then(imageModule => imageModule.default));
            }

            Promise.all(promises)
                .then(imageArray => {
                    setImages(imageArray);
                })
                .catch(error => {
                    console.error("Error importing images:", error);
                });
        }
    }, [projectId])

    return <>
        <Helmet>
            <title>Shizen Interior Design | Portofoliu</title>
            <meta name="description"
                content="Unul dintre proiectele din portofoliul Shizen Interior Design"
            />
            <link rel="canonical" href="https://shizenstudiodesgin.com/portofoliu/1"></link>
        </Helmet>
        <div className="grid grid-rows-1 justify-items-center gap-2 mx-2">
            <div className="flex justify-center text-center uppercase font-custom text-shizen text-3xl py-4 font-bold">
                {title}
            </div>
            <div className="grid gap-2">
                <div className="flex justify-center">
                    <div className="w-3/4">
                        {images.length > 0 && images.map((image) => (
                            <LazyLoadImage
                                src={image}
                                alt="portfolio"
                                key={image}
                                effect="blur"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default PortfolioProject;