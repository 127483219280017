import React from "react";
import Slider from "../components/slider/Slider";
import image1 from "../components/images/slider1.avif";
import image2 from "../components/images/slider2.avif";
import image3 from "../components/images/slider3.avif";
import image4 from "../components/images/slider4.avif";
import logo from "../components/images/logo.avif";
import about_us from "../components/images/about_us.avif";
import services from "../components/images/services.avif";
import ContentWithImage from "../components/homepage/ContentWithImage";
import HomepagePortfolio from "../components/homepage/HomepagePortfolio";
import { ROUTES } from "../constants/routes";
import FeedbackSection from "../components/feedback/FeedbackSection";
import { Helmet } from "react-helmet";

const Homepage = () => {
  const sliderData = [
    {
      image: image1,
      caption: "Elaborarea unei viziuni și a unei direcții creative, punând bazele întregului proces de dezvoltare a designului",
      label: "Concept",
      altText: "Concept",
    },
    {
      image: image2,
      caption: "Echilibrul perfect între estetică și utilitate, optimizând spațiul pentru a îndeplini nevoile și confortul utilizatorilor",
      label: "Funcționalitate",
      altText: "Funcționalitate",
    },
    {
      image: image3,
      caption: "Transformarea conceptului în specificații detaliate, asigurând claritate și eficientă în implementarea proiectului",
      label: "Detaliere tehnică",
      altText: "Detaliere tehnică",
    },
    {
      image: image4,
      caption: "Furnizarea unui suport continuu pe parcursul implementării, asigurând executarea conformă a proiectului",
      label: "Asistență în achiziții și șantier",
      altText: "Asistență în achiziții și șantier",
    }
  ];

  const abousUsContent =
  {
    image: about_us,
    altText: "Poza cu echipa",
    orientation: "left",
    content: {
      title: "Despre noi",
      text: "Situat în vibrantul oraș Cluj-Napoca, studioul nostru de design se angajează să transforme locuințele în spații cu adevărat distincte, care să reflecte personalitatea și gusturile unice ale beneficiarilor. Cu o abordare creativă și atentă la detalii, realizăm interioare care să fie o extensie autentică a vieții și pasiunilor clienților noștri, oferind soluții personalizate și inovatoare pentru fiecare proiect.",
      redirectUrl: ROUTES.DESPRE_NOI,
    }
  };

  const servicesContent =
  {
    image: services,
    altText: "Poza cu servicii",
    orientation: "right",
    content: {
      title: "Servicii",
      text: "Shizen Design oferă o paletă largă de servicii de design interior, perfect adaptate cerințelor și gusturilor fiecărui client. De la consultanță inițială și proiectare conceptuală, până la implementare și finalizare, ne implicăm alături de clienții noștri în fiecare etapă a procesului de transformare a spațiilor lor.",
      redirectUrl: ROUTES.SERVICII,
    }
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shizen Interior Design | Acasă </title>
        <meta name="og:description"
          content="Servicii complete de design interior"
        />
        <meta name="keywords" content="Design Interior, Design, Cluj-Napoca" />
        <link rel="canonical" href="https://shizenstudiodesgin.com/"></link>
      </Helmet>
      <Slider data={sliderData} />
      <div className="flex items-center justify-center py-5">
        <h1 className="hidden">Acasa</h1>
        <img src={logo} alt="logo" />
      </div>
      <div className="flex flex-col items-center justify-center py-4">
        <p className="text-3xl text-shizen text-center font-custom">
          In every corner of our home lies a story, and interior design is its storyteller.
        </p>
      </div>
      <ContentWithImage data={abousUsContent} />
      <ContentWithImage data={servicesContent} />
      <HomepagePortfolio />
      <FeedbackSection />
    </>
  );
};

export default Homepage;