import React from "react";
import PortfolioItem from "./PortfolioItem";
import image1 from "./projects/1/1.jpeg";
import image2 from "./projects/2/1.jpeg";
import image3 from "./projects/3/1.jpeg";
import image4 from "./projects/4/1.jpeg";
import image5 from "./projects/5/1.jpeg";
import image6 from "./projects/6/1.jpeg";
import image7 from "./projects/7/1.jpeg";
import image8 from "./projects/8/1.jpeg";
import image9 from "./projects/9/1.jpeg";
import image10 from "./projects/10/1.jpeg";
import image11 from "./projects/11/1.jpeg";
import image12 from "./projects/12/1.jpeg";
import image13 from "./projects/13/1.jpeg";
import image14 from "./projects/14/1.jpeg";


const PortfolioListing = () => {

    const data = [
        {
            image: image1,
            text: 'Sage Meadow Apartment',
            title: 'Sage Meadow Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '1',
        },
        {
            image: image2,
            text: 'Dark Industrial Apartment',
            title: 'Dark Industrial Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '2',
        },
        {
            image: image3,
            text: 'Warm Japandi Apartment',
            title: 'Warm Japandi Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '3',
        },
        {
            image: image4,
            text: 'Red Dot Apartment',
            title: 'Red Dot Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '4',
        },
        {
            image: image5,
            text: 'Liberty Apartment',
            title: 'Liberty Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '5',
        },
        {
            image: image6,
            text: 'Modern Farmhouse',
            title: 'Modern Farmhouse',
            type: 'Dej',
            redirectNumber: '6',
        },
        {
            image: image7,
            text: 'City Center House',
            title: 'City Center House',
            type: 'Craiova',
            redirectNumber: '7',
        },
        {
            image: image8,
            text: 'Olive Apartment',
            title: 'Olive Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '8',
        },
        {
            image: image9,
            text: 'The Nature Apartment',
            title: 'The Nature Apartment',
            type: 'Piatra Neamț',
            redirectNumber: '9',
        },
        {
            image: image10,
            text: 'Terazzo Airbnb Apartment',
            title: 'Terazzo Airbnb Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '10',
        },
        {
            image: image11,
            text: 'Circle Rental Apartment',
            title: 'Circle Rental Apartment',
            type: 'București',
            redirectNumber: '11',
        },
        {
            image: image12,
            text: 'River View Apartment',
            title: 'River View Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '12',
        },
        {
            image: image13,
            text: 'MA Apartment',
            title: 'MA Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '13',
        },
        {
            image: image14,
            text: 'Kids Room',
            title: 'Kids Room',
            type: 'Cluj-Napoca',
            redirectNumber: '14',
        }
    ];

    return (
        <div className={`md:mx-20`}>
            <div className="flex flex-col items-center justify-center py-4 md:mx-20 mx-2">
                <p className="text-3xl uppercase text-center font-bold font-custom text-stone-700">
                    <strong>Portofoliu</strong>
                </p>
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                    {data.map((element) => (
                        <PortfolioItem data={element} key={element.title} />
                    ))}
                </div>
            </div>
        </div>)
}

export default PortfolioListing;