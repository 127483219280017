import React from "react";
import PortfolioItem from "../portfolio/PortfolioItem";
import image1 from "../images/project1.avif";
import image2 from "../images/project2.avif";
import image3 from "../images/project3.avif";
import image4 from "../images/project4.avif";
import image5 from "../images/project5.avif";
import image6 from "../images/project6.avif";
import { ROUTES } from "../../constants/routes";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";


const HomepagePortfolio = () => {
    const data = [
        {
            image: image1,
            text: 'Sage Meadow Apartment',
            title: 'Sage Meadow Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '1',
        },
        {
            image: image2,
            text: 'Dark Industrial Apartment',
            title: 'Dark Industrial Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '2',
        },
        {
            image: image3,
            text: 'Warm Japandi Apartment',
            title: 'Warm Japandi Apartment',
            type: 'Cluj-Napoca',
            redirectNumber: '3',
        },
        {
            image: image4,
            text: 'The Nature Apartment',
            title: 'The Nature Apartment',
            type: 'Piatra Neamt',
            redirectNumber: '9',
        },
        {
            image: image5,
            text: 'City Center House',
            title: 'City Center House',
            type: 'Craiova',
            redirectNumber: '7',
        },
        {
            image: image6,
            text: 'Modern Farmhouse',
            title: 'Modern Farmhouse',
            type: 'Dej',
            redirectNumber: '6',
        }
    ];

    const [sectionRef, inView] = useInView({
        triggerOnce: true,
    });

    return <div ref={sectionRef} className={`animate__animated ${inView ? 'animate__fadeInUp' : ''}`}>
        <div className="flex flex-col items-center justify-center py-4 md:mx-20 w-auto">
            <p className="text-3xl uppercase text-center font-bold font-custom text-stone-700">
                <strong>Portofoliu</strong>
            </p>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                {data.map((element) => {
                    return <PortfolioItem data={element} key={element.title} />
                })}
            </div>
            <Link to={ROUTES.PORTOFOLIU} className="bg-shizen hover:bg-neutral-800 text-white px-4 py-2 rounded-md uppercase no-underline font-custom">Toate proiectele</Link>
        </div>
    </div>
}

export default HomepagePortfolio;