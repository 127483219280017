import React from "react";
import TopperImage from "../components/about/TopperImage";
import topperImage from "../components/images/services.avif";
import ConsultingComponent from "../components/services/ConsultingComponent";
import FullDesignServiceComponent from "../components/services/FullDesignService";
import { Helmet } from "react-helmet";

const Services = () => {
  const topData = {
    image: topperImage,
    text: "Serviciile noastre"
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shizen Interior Design | Servicii</title>
        <meta name="description"
          content="Gama completa de servicii de design interior ofertite de Shizen Interior Design"
        />
        <meta name="keywords" content="Design Interior, Servicii, Cluj-Napoca" />
        <link rel="canonical" href="https://shizenstudiodesgin.com/"></link>
      </Helmet>
      <h1 className="hidden">Servicii</h1>
      <TopperImage data={topData} />
      <ConsultingComponent />
      <FullDesignServiceComponent />
    </>
  );
}

export default Services; 