import React, { useEffect, useState } from "react";
import TextComponent from "./TextComponent";
import { useInView } from "react-intersection-observer";

const ContentWithImage = ({ data }) => {
    const [sectionRef, inView] = useInView();
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 800) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    }, [])

    return (
        <div ref={sectionRef} className="section py-4 md:mx-20">
            {
                isMobileView === true && <div className="flex justify-center md:space-x-4 flex-col-reverse md:flex-row md:mx-2">
                    <div className={`animate__animated ${inView ? 'animate__fadeInLeft' : ''} w-full md:w-1/2 flex justify-center items-center`}>
                        <img src={data.image} alt={data.altText} className="w-full h-auto" />
                    </div>
                    <div className={`animate__animated ${inView ? 'animate__fadeInRight' : ''} w-full md:w-1/2 flex justify-center items-center`}>
                        <TextComponent content={data.content} />
                    </div>
                </div>
            }
            {
                isMobileView === false && data.orientation === "left" &&
                <div className="flex justify-center md:space-x-4 flex-col-reverse md:flex-row md:mx-2">
                    <div className={`animate__animated ${inView ? 'animate__fadeInLeft' : ''} w-full md:w-1/2 flex justify-center items-center`}>
                        <img src={data.image} alt={data.altText} className="w-full h-auto" />
                    </div>
                    <div className={`animate__animated ${inView ? 'animate__fadeInRight' : ''} w-full md:w-1/2 flex justify-center items-center`}>
                        <TextComponent content={data.content} />
                    </div>
                </div>
            }
            {
                isMobileView === false && data.orientation === "right" &&
                <div className="flex justify-center md:space-x-4 flex-col-reverse md:flex-row md:mx-2">
                    <div className={`animate__animated ${inView ? 'animate__fadeInLeft' : ''} w-full md:w-1/2 flex justify-center items-center`}>
                        <TextComponent content={data.content} />
                    </div>
                    <div className={`animate__animated ${inView ? 'animate__fadeInRight' : ''} w-full md:w-1/2 flex justify-center items-center`}>
                        <img src={data.image} alt={data.altText} className="w-full h-auto" />
                    </div>
                </div>
            }
        </div >
    )
}


export default ContentWithImage;