import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const PortfolioItem = ({ data }) => {
    const navigate = useNavigate();

    const redirectAction = () => {
        navigate(ROUTES.PORTOFOLIU + '/' + data.redirectNumber);
    }

    return (
        <button onClick={redirectAction}>
            <div className="relative overflow-hidden">
                <LazyLoadImage
                    width={600}
                    height={400}
                    className="object-over w-full h-full hover:scale-110 hover:brightness-50"
                    src={data.image}
                    alt="Project Cover"
                    effect="blur"
                    style={{ transition: 'transform 0.7s ease-in-out' }}
                />
            </div>
            <div className="flex flex-col items-center text-shizen  font-custom font-bold">
                <p className="uppercase mb-0 text-2xl">{data.title}</p>
                <p className="text-shizen/70 text-xl">{data.type}</p>
            </div>
        </button>
    )
}

export default PortfolioItem;