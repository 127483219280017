import React from "react";
import { Carousel } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

const Slider = ({ data }) => {
    const [sectionRef, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <div ref={sectionRef} className={`animate__animated ${inView ? 'animate__fadeInDown' : ''}`}>
            <Carousel slide={true} wrap={true} interval={3500}>
                {data.map((element) => {
                    return (
                        <Carousel.Item key={element.label}>
                            <img
                                className="d-block w-100 darken"
                                src={element.image}
                                alt={element.altText}
                            />
                            <Carousel.Caption className="flex font-custom text-shizen items-center justify-center h-full slide-text">
                                <div>
                                    <h3 className="uppercase lg:text-3xl md:text-2xl font-bold">{element.label}</h3>
                                    <p className="lg:text-2xl md:text-xl">{element.caption}</p>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    );
}

export default Slider;