import React from "react";
import ReasonsComponent from "../components/about/ReasonsComponent";
import TopperImage from "../components/about/TopperImage";
import AboutUsSection from "../components/about/AboutUsSection";
import aboutUsImage from "../components/images/about_us.avif";
import { Helmet } from "react-helmet";

class AboutUs extends React.Component {

  render() {
    const aboutUsData = {
      image: aboutUsImage,
      text: 'Câteva cuvinte despre noi'
    }

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shizen Interior Design | Despre noi</title>
          <meta name="description"
            content="Cateva cuvinte despre Shizen Interior Design"
          />
          <meta name="keywords" content="Design Interior, Design, Cluj-Napoca" />
          <link rel="canonical" href="https://shizenstudiodesgin.com/"></link>
        </Helmet>
        <h1 className="hidden">Despre noi</h1>
        <TopperImage data={aboutUsData} />
        <ReasonsComponent />
        <AboutUsSection />
      </>
    );
  }
};

export default AboutUs;